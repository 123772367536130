@keyframes spinner {
	to {transform: rotate(360deg);}
}

$spinner-height-and-width: 105px;
$spinner-border-width: 12px;

$spinner-fill-height-and-width: $spinner-height-and-width - ($spinner-border-width * 2);
$spinner-z-index: 99999 !important;			// love it

$spinner-logo-height: 24px;


.app-spinner {
	.spinner-bg {
		z-index: $spinner-z-index;
		content: '';
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--gray-200);
	}

	.spinner {
		z-index: $spinner-z-index;
		display: block;
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: calc(50% - #{$spinner-height-and-width * 0.5});
		left: calc(50% - #{$spinner-height-and-width * 0.5});
		width: $spinner-height-and-width;
		height: $spinner-height-and-width;
		border-radius: 50%;
		border: $spinner-border-width solid var(--gray-200);
		border-top-color: var(--action-600, var(--default-primary-color));
		animation: spinner 1.3s linear infinite;
	}

	.loading-message {
		z-index: $spinner-z-index;
		display: block;
		position: absolute;
		top: 50%;
		width: 100%;
		text-align: center;
		padding: 20px;
		margin-top: 25px;
		display: none;
	}

	&.show-message {
		.spinner { display: none; }
		.loading-message { display: block; }
	}

	.spinner-fill {
		background: var(--white);
		position: absolute;
		left: calc(50% - #{$spinner-fill-height-and-width * 0.5});
		top: calc(50% - #{$spinner-fill-height-and-width * 0.5});
		height: $spinner-fill-height-and-width;
		width: $spinner-fill-height-and-width;
		z-index: $spinner-z-index;

		// used instead of utility classes to minimize load time
		display: flex; // cannot use the d-flex class on the element, or it will not fade properly
		border-radius: 100%;
		align-items: center;
		justify-content: center;;
	}

	img {
		height: $spinner-logo-height;
	}
}
