:root {
  --logo-url: url("../../img/QualtricsXM-Logo.png");
  --white: var(--white-100);
  --header-active-menu-bg: var(--custom-bg-header, var(--white-100));
  --header-link-color: var(--default-text-color);
  --header-link-color-hover: var(--default-primary-color, var(--gray-700));
  --modal-header-text-color: var(--gray-1000);
  --modal-header-background: var(--white);
  --modal-divider-color: var(--gray-500);
  --focus-outline-color: var(--action-1000);
  --focus-background-color: var(--action-700);
  --primary-bg: var(--custom-bg-primary, var(--gray-100));
  --dashboard-bg: var(--primary-bg);
  --button-hover-background: var(--action-800);
  --button-hover-border-color: var(--action-800);
  --button-hover-text-color: var(--white);
}

/* THIS FILE IS GENERATED, DON'T EDIT MANUALLY! */
/* THIS FILE IS GENERATED, DON'T EDIT MANUALLY! */
:root, .light-mode {
  --black: #000000;
  --danger-100: #fff4f3;
  --danger-200: #ffe5e3;
  --danger-300: #ffc7c4;
  --danger-400: #fda4a0;
  --danger-500: #f78483;
  --danger-600: #eb555c;
  --danger-700: #da1c3d;
  --danger-800: #ad1f32;
  --danger-900: #821e27;
  --danger-1000: #4d181a;
  --neg-sentiment-100: #fff4f2;
  --neg-sentiment-200: #ffe5e1;
  --neg-sentiment-300: #ffc7be;
  --neg-sentiment-400: #ffa497;
  --neg-sentiment-500: #ff8073;
  --neg-sentiment-600: #fb4343;
  --neg-sentiment-700: #d52930;
  --neg-sentiment-800: #aa2728;
  --neg-sentiment-900: #802220;
  --neg-sentiment-1000: #4b1a16;
  --pos-sentiment-100: #f0f9ee;
  --pos-sentiment-200: #d8f1d8;
  --pos-sentiment-300: #b2e189;
  --pos-sentiment-400: #81ce74;
  --pos-sentiment-500: #53bc41;
  --pos-sentiment-600: #239e0e;
  --pos-sentiment-700: #1d810b;
  --pos-sentiment-800: #176809;
  --pos-sentiment-900: #125007;
  --pos-sentiment-1000: #0b3104;
  --warning-100: #fff4ef;
  --warning-200: #ffe6d9;
  --warning-300: #ffc9ad;
  --warning-400: #ffa677;
  --warning-500: #f8884b;
  --warning-600: #e55f06;
  --warning-700: #ba4f0d;
  --warning-800: #944110;
  --warning-900: #703310;
  --warning-1000: #43210e;
  --success-100: #f3f8ef;
  --success-200: #e3efda;
  --success-300: #c3dcb0;
  --success-400: #9ec782;
  --success-500: #7cb559;
  --success-600: #529a2a;
  --success-700: #457d25;
  --success-800: #396420;
  --success-900: #2d4c18;
  --success-1000: #1e2e14;
  --aqua-100: #eef8f9;
  --aqua-200: #d8eff2;
  --aqua-300: #abdde3;
  --aqua-400: #77c8d2;
  --aqua-500: #47b4c1;
  --aqua-600: #1a97a5;
  --aqua-700: #157b87;
  --aqua-800: #11636c;
  --aqua-900: #0d4c53;
  --aqua-1000: #082f33;
  --action-100: #f1f7fd;
  --action-200: #ddedf8;
  --action-300: #b7d8f7;
  --action-400: #8ac1f2;
  --action-500: #62abed;
  --action-600: #278ce6;
  --action-700: #006fd3;
  --action-800: #0059aa;
  --action-900: #004582;
  --action-1000: #002b51;
  --effort-easy-100: #f5f6fd;
  --effort-easy-200: #e8eafb;
  --effort-easy-300: #ced2f7;
  --effort-easy-400: #afb8f1;
  --effort-easy-500: #92a2ec;
  --effort-easy-600: #6484e5;
  --effort-easy-700: #1f6bde;
  --effort-easy-800: #1a56b3;
  --effort-easy-900: #1f4186;
  --effort-easy-1000: #1b284d;
  --effort-hard-100: #fff4ef;
  --effort-hard-200: #ffe6d9;
  --effort-hard-300: #ffc9ad;
  --effort-hard-400: #ffa67a;
  --effort-hard-500: #f9874e;
  --effort-hard-600: #e95b03;
  --effort-hard-700: #be4b09;
  --effort-hard-800: #973e0d;
  --effort-hard-900: #72310f;
  --effort-hard-1000: #44200d;
  --emotional-intensity-100: #f8f5fe;
  --emotional-intensity-200: #efe8fc;
  --emotional-intensity-300: #dccdf9;
  --emotional-intensity-400: #c8b0f6;
  --emotional-intensity-500: #b595f3;
  --emotional-intensity-600: #9b70ef;
  --emotional-intensity-700: #824deb;
  --emotional-intensity-800: #6827e6;
  --emotional-intensity-900: #4e15bd;
  --emotional-intensity-1000: #310e77;
  --blue-100: #f1f7fd;
  --blue-200: #ddedf8;
  --blue-300: #b7d8f7;
  --blue-400: #8ac1f2;
  --blue-500: #62abed;
  --blue-600: #278ce6;
  --blue-700: #006fd3;
  --blue-800: #0059aa;
  --blue-900: #004582;
  --blue-1000: #002b51;
  --gray-100: #f6f6f8;
  --gray-200: #ebebeb;
  --gray-300: #d4d4d4;
  --gray-400: #bbb;
  --gray-500: #a6a6a6;
  --gray-600: #898989;
  --gray-700: #6f6f6f;
  --gray-800: #595959;
  --gray-900: #444;
  --gray-1000: #2a2a2a;
  --white-100: #fff;
  --white-200: #fff;
  --white-300: #fff;
  --white-400: #fff;
  --white-500: #fff;
  --white-600: #151515;
  --white-700: #151515;
  --white-800: #151515;
  --white-900: #151515;
  --white-1000: #151515;
  --white: var(--white-100, #fff);
  --info-100: var(--gray-100, #f6f6f8);
  --info-200: var(--gray-200, #ebebeb);
  --info-300: var(--gray-300, #d4d4d4);
  --info-400: var(--gray-400, #bbb);
  --info-500: var(--gray-500, #a6a6a6);
  --info-600: var(--gray-600, #898989);
  --info-700: var(--gray-700, #6f6f6f);
  --info-800: var(--gray-800, #595959);
  --info-900: var(--gray-900, #444);
  --info-1000: var(--gray-1000, #2a2a2a);
  --header-background-color: var(--gray-900, #43444f);
  --header-text-color: var(--gray-400, #babbc5);
  --header-text-hover-color: var(--white, #ffffff);
  --header-border-color: var(--gray-900, #43444f);
  --sidebar-background-color: var(--gray-900, #43444f);
  --sidebar-text-color: var(--white, #ffffff);
  --sidebar-subtext-color: var(--gray-400, #babbc5);
  --third-party-facebook-color: var(--third-party-facebook-color, #1877f2);
  --third-party-twitter-color: var(--third-party-twitter-color, #1da1f2);
  --third-party-apptentive-color: var(--third-party-apptentive-color, #F04B5A);
  --third-party-bazaarvoice-color: var(--third-party-bazaarvoice-color, #002E6E);
  --third-party-bold360-color: var(--third-party-bold360-color, #19BA69);
  --third-party-brandwatch-color: var(--third-party-brandwatch-color, #8AC539);
  --third-party-cfpb-color: var(--third-party-cfpb-color, #20AA3F);
  --third-party-confirmit-color: var(--third-party-confirmit-color, #FF7813);
  --third-party-egain-color: var(--third-party-egain-color, #000000);
  --third-party-foresee-color: var(--third-party-foresee-color, #EE2737);
  --third-party-gladly-color: var(--third-party-gladly-color, #009B00);
  --third-party-khoros-color: var(--third-party-khoros-color, #2E1CD4);
  --third-party-liveperson-color: var(--third-party-liveperson-color, #FF6900);
  --third-party-medallia-color: var(--third-party-medallia-color, #4050C6);
  --third-party-opinator-color: var(--third-party-opinator-color, #000000);
  --third-party-powerreviews-color: var(--third-party-powerreviews-color, #020101);
  --third-party-qualtrics-color: var(--third-party-qualtrics-color, #0768DD);
  --third-party-reputation-color: var(--third-party-reputation-color, #004F5D);
  --third-party-cpsc-color: var(--third-party-cpsc-color, #23407D);
  --third-party-salesforce-color: var(--third-party-salesforce-color, #009EDB);
  --third-party-socialstudio-color: var(--third-party-socialstudio-color, #00A1E0);
  --third-party-surveymonkey-color: var(--third-party-surveymonkey-color, #00BF6F);
  --third-party-synthesio-color: var(--third-party-synthesio-color, #3E95B5);
  --third-party-tripadvisor-color: var(--third-party-tripadvisor-color, #34E0A1);
  --third-party-trustpilot-color: var(--third-party-trustpilot-color, #000032);
  --third-party-yotpo-color: var(--third-party-yotpo-color, #0042E4);
  --third-party-zendesk-color: var(--third-party-zendesk-color, #17494D);
  --black-50-percent: rgba(0, 0, 0, .5);
  --modal-background: rgba(42,42,42,0.5);
}

/* shadows */
.color-inherit {
  color: inherit;
}

.bg-none {
  background: transparent !important;
}

/* buttons */
/* overwrite bootstrap alert colors */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.app-spinner .spinner-bg {
  z-index: 99999 !important;
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray-200);
}
.app-spinner .spinner {
  z-index: 99999 !important;
  display: block;
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - 52.5px);
  left: calc(50% - 52.5px);
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 12px solid var(--gray-200);
  border-top-color: var(--action-600, var(--default-primary-color));
  animation: spinner 1.3s linear infinite;
}
.app-spinner .loading-message {
  z-index: 99999 !important;
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  padding: 20px;
  margin-top: 25px;
  display: none;
}
.app-spinner.show-message .spinner {
  display: none;
}
.app-spinner.show-message .loading-message {
  display: block;
}
.app-spinner .spinner-fill {
  background: var(--white);
  position: absolute;
  left: calc(50% - 40.5px);
  top: calc(50% - 40.5px);
  height: 81px;
  width: 81px;
  z-index: 99999 !important;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}
.app-spinner img {
  height: 24px;
}